import Header from "../components/header";

function Home () {
    return (
        <div className="main home">
            <a className="skip-to-content" href="#main">Skip to content</a>
            <Header pageName="home" />
            <main id="main" className="grid-container grid-container--home">
            <div className="direction-rtl">
                <h1 className="text-accent fs-500 ff-sans-cond uppercase letter-spacing-1">Developer, Board Gamer, Cyclist, Legend*‎
                <span className="fs-900 ff-serif text-dark d-block">jonnus‎</span>
                </h1>
                <p className="text-accent">A breif portfolio of stuff I've worked on, technologies I use, and some of my other interests.‎</p>
                <span className="text-accent fs-200 ff-sans-cond"><span>‎</span>* Legendary status still pending official certification.‎</span>
            </div>
            </main>
        </div>
    )
}

export default Home;