import Header from "../components/header";
import NumberedTitle from "../components/numberedTitle";

function AboutInterests () {
    return (
        <div className="main about">
            <a className="skip-to-content" href="#main">Skip to content</a>
            <Header 
                pageName="aboutinterests" 
            />
            <main id="main" className="grid-container grid-container--about text-dark direction-ltr">

                <div className="grid-body-content">
                    <div>
                        <h1>
                            Professional Interests
                        </h1>
                        <div>
                        <p>My particular interests lie in improving quality of software delivery, promoting the adoption and use of Unit Tests and other forms of automated testing. In an ideal world, I believe manual testing of applications should be a last resort.</p>
                        <p>I am also an advocate of the Agile development process. Through rapid iterations delivering incremental improvements with frequent customer feedback, delivery of value to the end user can be achieved in an optimal way. An integrated, highly communicative team working towards a common goal is a recipe for success.</p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default AboutInterests;