import Header from "../components/header";

function Projects () {
    return (
        <div className="main about">
            <a className="skip-to-content" href="#main">Skip to content</a>
            <Header 
                pageName="projects" 
            />
            <main id="main" className="grid-container grid-container--about text-dark direction-ltr">

                <div className="grid-body-content">
                    <div>
                        <h1>Projects</h1>
                        <div>
                            <ul>
                                <li>
                                    <a href="https://myenergy.jonnus.co.uk/" target="_blank" >MyEnergy</a><br/>
                                    <span>Query how much energy I've either pulled from the grid, or exported for cash</span>
                                </li><br/>
                                <li>
                                    <a href="https://www.dorchesterboardgames.co.uk/" target="_blank" >Dorchester Board Games</a><br/>
                                    <span>Website advertising our weekly Board Games group</span>
                                </li><br/>
                                <li>
                                    <a href="https://cosmic-stroopwafel-0b439b.netlify.app/" target="_blank" >Tenzies</a><br/>
                                    <span>Get 10 matching dice.  Can you beat your high score?</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Projects;