import Header from "../components/header"
import SukiImage from "../images/Suki.jpg"

function AboutPersonal () {
    return (
        <div className="main about">
            <a className="skip-to-content" href="#main">Skip to content</a>
            <Header 
                pageName="aboutpersonal" 
            />
            <main id="main" className="grid-container grid-container--about text-dark direction-ltr">

                <div className="grid-body-content">
                    <div>
                        <h1>Personal Life</h1>
                        <div>
                        <p>I am a father to two (rapidly growing) children who enjoy nothing more than eating, gluing, sticking, and making other forms of mess.</p>
                        <p>Since relocating to Dorset, I have been trying to take advantage of the great outdoors. Be that by bike, hiking in the countryside, or simply going to the beach and building sandcastles.</p>
                        <p>In 2022 we welcomed the latest member of our family, Suki, a rescued Brittany Spaniel, who barks with a distinctly Greek accent.</p>
                        <img className="image-suki" src={SukiImage} alt="Suki on the beach"></img><br/>
                        <p>I organise <a href="https://www.dorchesterboardgames.co.uk/" target="_blank" >Dorchester Board Games</a>, an informal group meeting weekly at a local pub to play a variety of modern board and card games.</p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default AboutPersonal;