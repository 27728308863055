function NumberedTitle ({isActive, number, text, url}) {
    const spanStyles = {
        marginRight: ".5rem",
        fontWeight: "700"        
    }

    return (
        <li className={`${isActive ? "active": ""}`}>
            <a 
                href={url}
                className={"uppercase ff-sans-cond text-accent letter-spacing-2 numbered-title"}
            >
                    <span aria-hidden="true">{number.toString().padStart(2, "0")}</span>
                    {text}
            </a>
        </li>
    )
}

export default NumberedTitle;