import Header from "../components/header";

function AboutWork () {
    return (
        <div className="main about">
            <a className="skip-to-content" href="#main">Skip to content</a>
            <Header 
                pageName="aboutwork" 
            />
            <main id="main" className="grid-container grid-container--about text-dark direction-ltr">

                <div className="grid-body-content">
                    <div>
                        <h1>
                            Work History
                        </h1>
                        <div>
                        <p>I am currently a Software Delivery lead for Flowbird, a Transport solutions company, responsible for Ticket Vending Machines deployed across the UK and Australia.</p>
                        <p>My former role was as a a Senior Software Enginner for Tessian, an email security company.  I work in the .net team supporting the business with the logic required to run the rules that detect is an email is malicious or not.</p>
                        <p>Previously, I worked for Morning Data, based in the Dorchester office.  I was responsible for the maintenance and improvement of our primary Novus application.  In addition, I was responsible for creating our new RESTful API, created in .net Core 3.1.</p>
                        <p>Before that, I worked for Mouser Electronics, as a Lead Web Developer for the Internet Business team. I was on the team reworking this legacy website to take advantage of the benefits we can gain from applying MVC to this solution.</p>
                        <p>Prior to this, I was a Full-Stack Applications Developer for a FTSE 100 company, working on Line of Business applications. These were primarily coded in C#.NET. There were also a number of legacy applications written in VB6 that I was in the process of re-hosting in ASP.NET MVC5 and using Entity Framework 6.</p>
                        <p>I have previously held the role of SCRUM Master and been part of an Agile team who were developing a Dashboard for Project Managers incorporating information for a disparate number of external applications, using SSIS, SSAS, and SSRS.</p>
                        </div>
                    </div>
                    <div>
                        <h2>
                        Contact
                        </h2>
                        <div>
                        <p>You can contact me at <strong><a href="mailto:contact@jonnus.co.uk">contact@jonnus.co.uk</a></strong>
                        </p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default AboutWork;