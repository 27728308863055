import NumberedTitle from '../components/numberedTitle';

function DesignSystem() {
    const colourStyles = {
        padding: "3rem 1rem 1rem",
        border: "1px solid white"
      }

    const flexStyles = {
        flexGrow: "1"
    }

    const typographyStyles = {
        margin: "4rem 0"
    }

    const flexBasis100Styles = {
        flexBasis: "100%"
    }

    const fiftvhStyles = {
        marginBottom: "50vh"
    }

    return (
        <body className='main'>
            <h1>Design System</h1>

            <section id="colours">
                <NumberedTitle number={1} text="Colours" />

                <div className="flex">
                    <div style={flexStyles}>
                        <div className='text-white bg-dark' style={colourStyles}>
                            #0B0D17
                        </div>
                        <p><span className='text-white'>RGB</span> 11, 13, 23</p>
                        <p><span className='text-white'>HSL</span> 230°, 35% 7%</p>
                    </div>
                    <div style={flexStyles}>
                        <div className='text-dark bg-light' style={colourStyles}>
                            #D0D6F9
                        </div>
                        <p><span className='text-white'>RGB</span> 208, 214, 249</p>
                        <p><span className='text-white'>HSL</span> 231°, 77% 90%</p>
                    </div>
                    <div style={flexStyles}>
                        <div className='text-dark bg-white' style={colourStyles}>
                            #FFFFFF
                        </div>
                        <p><span className='text-white'>RGB</span> 255, 255, 255</p>
                        <p><span className='text-white'>HSL</span> 0°, 0% 100%</p>
                    </div>
                </div>
            </section>

            <section id="typography" style={typographyStyles}>
                <NumberedTitle number={2} text="Typography" />
                <div className="flex">
                    <div className="flow" style={flexBasis100Styles}>
                        <div> 
                            <p className="text-light">Heading 1 - Bellefair Regular - 150px</p>
                            <p className="fs-900 ff-serif uppercase">Earth</p>
                        </div>
                        <div> 
                            <p className="text-light">Heading 2 - Bellefair Regular - 100px</p>
                            <p className="fs-800 ff-serif uppercase">Venus</p>
                        </div>
                        <div> 
                            <p className="text-light">Heading 3 - Bellefair Regular - 56px</p>
                            <p className="fs-700 ff-serif uppercase">Jupiter & Saturn</p>
                        </div>
                        <div> 
                            <p className="text-light">Heading 4 - Bellefair Regular - 32px</p>
                            <p className="fs-600 ff-serif uppercase">Uranus, Neptune, & Pluto</p>
                        </div>
                        <div> 
                            <p className="text-light">Heading 5 - Barlow Condensed Regular - 28px - 4.75 Character Space</p>
                            <p className="text-light fs-500 ff-sans-condensed uppercase letter-spacing-1">So, you want to travel to space</p>
                        </div>
                    
                    </div>
                    
                    <div className='flow' style={flexBasis100Styles}>
                        <div> 
                            <p className="text-light">Subheading 1 - Bellefair Regular - 28px</p>
                            <p className="fs-500 ff-serif uppercase">384,400 km</p>
                        </div>
                        <div> 
                            <p className="text-light">Subheading 2 - Barlow Condensed Regular - 14px - 2.35 Character Space</p>
                            <p className="fs-200 uppercase ff-sans-cond letter-spacing-3">Avg. Distance</p>
                        </div>
                        <div> 
                            <p className="text-light">Nav Text - Barlow Condensed Regular - 16px - 2.7 Character Space</p>
                            <p className="fs-300 uppercase ff-sans-cond letter-spacing-2">Europa</p>
                        </div>
                        <div> 
                            <p className="text-light">Body Text</p>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Suspendisse potenti.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Suspendisse potenti.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='flow' id="interactive-elements">
                <NumberedTitle number={3} text="Interactive elements" />
                
                {/* navigation */}
                <div >
                    <nav>
                        <ul className="primary-navigation underline-indicators flex">
                            <li className='active'><a href="#" className="uppercase ff-sans-cond text-white letter-spacing-2"><span>01</span>Active</a></li>
                            <li><a href="#" className="uppercase ff-sans-cond text-white letter-spacing-2"><span>02</span>Hovered</a></li>
                            <li><a href="#" className="uppercase ff-sans-cond text-white letter-spacing-2"><span>03</span>Idle</a></li>
                        </ul>
                    </nav>
                </div>
                
                <div className="flex">
                    <div>
                        {/* explore button */}
                        <a href="#" className='large-button uppercase ff-serif fs-600 text-dark bg-white'>Explore</a>
                    </div>
                    
                    <div className='flow' style={fiftvhStyles}>
                        {/* Tabs */}
                        <div className='tab-list underline-indicators flex'>
                            <button aria-selected="true" className="uppercase ff-sans-cond text-light bg-dark letter-spacing-2">Moon</button>
                            <button aria-selected="false" className="uppercase ff-sans-cond text-light bg-dark letter-spacing-2">Mars</button>
                            <button aria-selected="false" className="uppercase ff-sans-cond text-light bg-dark letter-spacing-2">Europa</button>
                        </div>
                        
                        {/* Dots */}
                        <div className='dot-indicators flex'>
                            <button aria-selected="true"><span className='sr-only'>Slide Title</span></button>
                            <button aria-selected="false"><span className='sr-only'>Slide Title</span></button>
                            <button aria-selected="false"><span className='sr-only'>Slide Title</span></button>
                        </div>
                        
                        {/* Numbers */}
                        <div className='numbered-indicators flex flex-vertical'>
                            <button aria-selected="true" className="uppercase ff-serif text-light bg-dark letter-spacing-2">1</button>
                            <button aria-selected="false" className="uppercase ff-serif text-light bg-dark letter-spacing-2">2</button>
                            <button aria-selected="false" className="uppercase ff-serif text-light bg-dark letter-spacing-2">3</button>
                        </div>
                    </div>
                </div>
                
            </section>
        </body>
    );
}

export default DesignSystem;