import { useState } from 'react';
import NumberedTitle from './numberedTitle';

function Header({pageName}) {
    var [menuOpen, setMenuOpen] = useState(true);

    function handleClick() {
        console.log("hello" + menuOpen)
        setMenuOpen(!menuOpen);
    }

    return (
        <header className="primary-header flex">
            <div></div>
            <button 
                className={`mobile-nav-toggle ${menuOpen ? "display-open-button" : "display-close-button"}`}
                aria-controls="primary-navigation" 
                aria-expanded="false"
                onClick={handleClick}
            >
                <span className='sr-only'>Menu</span>
            </button>
            <nav>
                <ul id="primary-navigation" className={`primary-navigation underline-indicators flex ${menuOpen ? "menu-open" : ""}`}>
                    <NumberedTitle isActive={pageName === "home"} number="0" text="Home" url="/"/>
                    <NumberedTitle isActive={pageName === "aboutwork"} number="1" text="Work" url="/aboutwork"/>
                    <NumberedTitle isActive={pageName === "aboutinterests"} number="2" text="Interests" url="/aboutinterests"/>
                    <NumberedTitle isActive={pageName === "aboutpersonal"} number="3" text="Personal" url="/aboutpersonal"/>
                    <NumberedTitle isActive={pageName === "projects"} number="4" text="Projects" url="/projects"/>
                </ul>
            </nav>
        </header>
    )
}

export default Header;