import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './newIndex.css';
import Home from './pages/home';
import DesignSystem from './pages/designSystem';
import AboutWork from './pages/aboutWork';
import AboutInterests from './pages/aboutInterests';
import AboutPersonal from './pages/aboutPersonal';
import Projects from './pages/projects';

function App() {
  return (
    <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/DesignSystem" element={<DesignSystem />} />
            <Route path="/Home" element={<Home />} />  
            <Route path="/AboutWork" element={<AboutWork />} />
            <Route path="/AboutInterests" element={<AboutInterests />} />
            <Route path="/AboutPersonal" element={<AboutPersonal />} />
            <Route path="/Projects" element={<Projects />} />
        </Routes>
    </Router>
  );
}

export default App;
